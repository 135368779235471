import { Component } from "react";

export default class OfferSection extends Component{
    render(){
        return(
            <section className="section countdown-part">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mx-auto">
                            <div className="countdown-content">
                                <h3>Get 10% discount over all our items on your first online shopping with us.</h3>
                                <div className="countdown-time" data-countdown="2024/12/30"></div>
                                <a href="/signup" className="btn btn-inline"><i className="far fa-registration"></i><span>signup now</span></a>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="countdown-img">
                                <img src="assets/img/backgroundoffer.png" alt="countdown" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}