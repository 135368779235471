import { Component } from "react";

export default class ProductCard extends Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div class="col">
                   <div class="product-card">
                               <div class="product-media">
                                  {this.props.isnew == true? <div class="product-label"><label class="label-text new">new</label></div>:''}
                                   <a class="product-image" href="#"><img src={this.props.img} alt="product" /></a>
                               </div>
                               <div class="product-content">
                                   <h6 class="product-name"><a href="#">{this.props.name}</a></h6>
                               </div>
                   </div>
            </div>
        )
    }
}