import { Component } from "react";

export default class Footer extends Component{
    render(){
        return(
            <footer className="footer-part">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-xl-3">
                            <div className="footer-widget">
                                <a className="footer-logo" href="/#"><img src="assets/img/logo.png" alt="logo" /></a>
                                <p className="footer-desc">Follow us on facebook</p>
                                <ul className="footer-social">
                                {/* eslint-disable-next-line*/}
                                    <li><a className="fab fa-facebook-f" href="https://www.facebook.com/BDvegis/"></a></li> {/* eslint-disable-next-line*/}
                                  
                                </ul>
                            </div>
                        </div>
                      
                        <div className="col-sm-3 col-xl-3">
                            <div className="footer-widget contact">
                                <h3 className="footer-title">Contact us</h3>
                                <ul className="footer-contact">
                                    <li>
                                        <i className="fal fa-envelope-open-text"></i>
                                        <p><span>{process.env.REACT_APP_RF_EMAIL}</span></p>
                                    </li>
                                    <li>
                                        <i className="fal fa-phone"></i>
                                        <p><span>{process.env.REACT_APP_RF_PHONE_NUMBER}</span></p>
                                    </li>
                                    <li>
                                        <i className="fal fa-map-marker-alt"></i>
                                        <p>{process.env.REACT_APP_RF_ADDRESS}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-sm-6 col-xl-6">
                            <div className="footer-widget">
                                <h3 className="footer-title">Visit us</h3>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.486043592731!2d150.78985621521244!3d-33.954344780632674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12edd831913c59%3A0x71dc38e0e0b5681e!2sRamin%E2%80%99s%20FARM%20Pty%20Ltd!5e0!3m2!1sen!2sau!4v1629551558309!5m2!1sen!2sau" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-bottom">
                                <p className="footer-copytext">&copy;Copyright 2021 <a  href="/#">RAMIN'S FARM</a> All Rights Reserved.</p>
                                <div className="footer-card">
                                {/*
                                    <a href="/#"><img src="assets/img/payment/jpg/01.jpg" alt="payment" /></a>
                                    <a href="/#"><img src="assets/img/payment/jpg/02.jpg" alt="payment" /></a>
                                    <a href="/#"><img src="assets/img/payment/jpg/03.jpg" alt="payment" /></a>
                                    <a href="/#"><img src="assets/img/payment/jpg/04.jpg" alt="payment" /></a>
                                */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}