import { Component, Fragment } from "react";
import Header from '../components/Header'
import MobileMenu from '../components/MobileMenu';
import HeroSlider from '../components/HeroSlider';
import OurProductSection from '../components/OurProductSection';
import OfferSection from '../components/OfferSection';
import Footer from '../components/Footer';

export default class Home extends Component{
    render(){
        return(
            <Fragment>
                <Header />
                <MobileMenu />
                <HeroSlider />
                <OurProductSection />
                <OfferSection />
                <Footer />
            </Fragment>
        )
    }
}