import { Component } from "react";

export default class NavBar extends Component{
    render(){
        return(
        <nav className="navbar-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar-content">
                            <ul className="navbar-list">
                                <li className="navbar-item dropdown">
                                    <a className="navbar-link dropdown-arrow" href="/#">home</a>
                                    <ul className="dropdown-position-list">
                                        <li><a href="index.html">Home Demo One</a></li>
                                        <li><a href="index-2.html">Home Demo Two</a></li>
                                        <li><a href="index-3.html">Home Demo Three</a></li>
                                        <li><a href="index-4.html">Home Demo Four</a></li>
                                    </ul>
                                </li>
                                <li className="navbar-item dropdown">
                                    <a className="navbar-link dropdown-arrow" href="/#">shop</a>
                                    <ul className="dropdown-position-list">
                                        <li><a href="shop-grid.html">shop grid</a></li>
                                        <li><a href="shop-standard.html">shop standard</a></li>
                                        <li><a href="product-details.html">product details</a></li>
                                    </ul>
                                </li>
                                <li className="navbar-item dropdown-megamenu">
                                    <a className="navbar-link dropdown-arrow" href="/#">category</a>
                                    <div className="megamenu">
                                        <div className="container">
                                            <div className="row row-cols-5">
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">fresh vegetables</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">fruits & juice</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">dairy & eggs </h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">seafoods & fishes</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <img src="assets/img/banner/8.jpg" className="w-100" alt="" />
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">fast foods</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">soft drinks</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">meats</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">dry foods</h5>
                                                        <ul className="megamenu-list">
                                                            <li><a href="/#">carrot</a></li>
                                                            <li><a href="/#">broccoli</a></li>
                                                            <li><a href="/#">cauliflower</a></li>
                                                            <li><a href="/#">cucumber</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <img src="assets/img/banner/9.jpg" className="w-100" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="navbar-item dropdown">
                                    <a className="navbar-link dropdown-arrow" href="/#">pages</a>
                                    <ul className="dropdown-position-list">
                                        <li><a href="about.html">about us</a></li>
                                        <li><a href="contact.html">contact us</a></li>
                                        <li><a href="offer.html">offers</a></li>
                                        <li><a href="faq.html">faqs</a></li>
                                        <li><a href="compare.html">compare</a></li>
                                        <li><a href="privacy.html">privacy policy</a></li>
                                        <li><a href="coming-soon.html">coming soon</a></li>
                                        <li><a href="blank-page.html">blank page</a></li>
                                        <li><a href="error.html">404 Error</a></li>
                                        <li><a href="wishlist.html">wishlist</a></li>
                                        <li><a href="checkout.html">checkout</a></li>
                                        <li><a href="cart.html">cart</a></li>
                                        <li><a href="orderlist.html">order list</a></li>
                                        <li><a href="order-detail.html">order details</a></li>
                                        <li><a href="invoice.html">order invoice</a></li>
                                    </ul>
                                </li>
                                <li className="navbar-item dropdown">
                                    <a className="navbar-link dropdown-arrow" href="/#">account</a>
                                    <ul className="dropdown-position-list">
                                        <li><a href="profile.html">my profile</a></li>
                                        <li><a href="login.html">login</a></li>
                                        <li><a href="register.html">register</a></li>
                                        <li><a href="reset.html">reset password</a></li>
                                        <li><a href="change.html">change password</a></li>
                                    </ul>
                                </li>
                                <li className="navbar-item dropdown">
                                    <a className="navbar-link dropdown-arrow" href="/#">blogs</a>
                                    <ul className="dropdown-position-list">
                                        <li><a href="blog-grid.html">blog grid</a></li>
                                        <li><a href="blog-standard.html">blog standard</a></li>
                                        <li><a href="blog-details.html">blog details</a></li>
                                    </ul>
                                </li>
                            </ul>
                            <div className="navbar-info-group">
                                <div className="navbar-info">
                                    <i className="fal fa-phone"></i>
                                    <p><small>call us</small><span>(+1) 123 455 9874</span></p>
                                </div>
                                <div className="navbar-info">
                                    <i className="fal fa-envelope-open-text"></i>
                                    <p><small>email us</small><span>info@example.com</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        )
    }
}