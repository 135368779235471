import { Component } from "react";

export default class CartSidebar extends Component{
    cartclosehandler = ()=>{
        this.props.cartclose();
    }
    render(){
        return(
            <aside className={this.props.isactive?"cart-part active":"cart-part"}>
                 <div className="cart-container">
                <div className="cart-header">
                    <div className="cart-total"><i className="far fa-shopping-cart"></i><span>total item (5)</span></div>
                    <button className="cart-close" onClick={this.cartclosehandler}><i className="far fa-times"></i></button>
                </div>
                <ul className="cart-list">
                    <li className="cart-item">
                        <div className="cart-media">
                            <a href="/#"><img src="assets/img/product/01.png" alt="product" /></a><button className="cart-delete"><i className="far fa-times"></i></button>
                        </div>
                        <div className="cart-info-group">
                            <div className="cart-info">
                                <h6><a href="product-single.html">fresh organic apple</a></h6>
                                <p>Price - $15.00</p>
                            </div>
                            <div className="cart-action-group">
                                <div className="product-action">
                                    <button className="action-minus" title="Quantity Minus"><i className="far fa-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" />
                                    <button className="action-plus" title="Quantity Plus"><i className="far fa-plus"></i></button>
                                </div>
                                <h6>$40.00</h6>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="cart-media">
                            <a href="/#"><img src="assets/img/product/02.png" alt="product" /></a><button className="cart-delete"><i className="far fa-times"></i></button>
                        </div>
                        <div className="cart-info-group">
                            <div className="cart-info">
                                <h6><a href="product-single.html">fresh organic apple</a></h6>
                                <p>Price - $15.00</p>
                            </div>
                            <div className="cart-action-group">
                                <div className="product-action">
                                    <button className="action-minus" title="Quantity Minus"><i className="far fa-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" />
                                    <button className="action-plus" title="Quantity Plus"><i className="far fa-plus"></i></button>
                                </div>
                                <h6>$40.00</h6>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="cart-media">
                            <a href="/#"><img src="assets/img/product/03.png" alt="product" /></a><button className="cart-delete"><i className="far fa-times"></i></button>
                        </div>
                        <div className="cart-info-group">
                            <div className="cart-info">
                                <h6><a href="product-single.html">fresh organic apple</a></h6>
                                <p>Price - $15.00</p>
                            </div>
                            <div className="cart-action-group">
                                <div className="product-action">
                                    <button className="action-minus" title="Quantity Minus"><i className="far fa-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" />
                                    <button className="action-plus" title="Quantity Plus"><i className="far fa-plus"></i></button>
                                </div>
                                <h6>$40.00</h6>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="cart-media">
                            <a href="/#"><img src="assets/img/product/04.png" alt="product" /></a><button className="cart-delete"><i className="far fa-times"></i></button>
                        </div>
                        <div className="cart-info-group">
                            <div className="cart-info">
                                <h6><a href="product-single.html">fresh organic apple</a></h6>
                                <p>Price - $15.00</p>
                            </div>
                            <div className="cart-action-group">
                                <div className="product-action">
                                    <button className="action-minus" title="Quantity Minus"><i className="far fa-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" />
                                    <button className="action-plus" title="Quantity Plus"><i className="far fa-plus"></i></button>
                                </div>
                                <h6>$40.00</h6>
                            </div>
                        </div>
                    </li>
                    <li className="cart-item">
                        <div className="cart-media">
                            <a href="/#"><img src="assets/img/product/05.png" alt="product" /></a><button className="cart-delete"><i className="far fa-times"></i></button>
                        </div>
                        <div className="cart-info-group">
                            <div className="cart-info">
                                <h6><a href="product-single.html">fresh organic apple</a></h6>
                                <p>Price - $15.00</p>
                            </div>
                            <div className="cart-action-group">
                                <div className="product-action">
                                    <button className="action-minus" title="Quantity Minus"><i className="far fa-minus"></i></button><input className="action-input" title="Quantity Number" type="text" name="quantity" value="1" />
                                    <button className="action-plus" title="Quantity Plus"><i className="far fa-plus"></i></button>
                                </div>
                                <h6>$40.00</h6>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="cart-footer">
                    <a className="cart-checkout-btn" href="checkout.html"><span className="checkout-label">Proceed to Checkout</span><span className="checkout-price">$200.00</span></a>
                </div>
            </div>
        </aside>
        )
    }
}