import { Component } from "react";
import ProductCard from '../components/ProductCard'

export default class OurProductSection extends Component{
    constructor(props){
        super(props);
        const products = [
            {name:'Longmelon',img: '/assets/img/product/1_longmelon.png'},
            {name:'Longmelon white', img:'assets/img/product/2_Longmelon-white.png'},
            {name:'Pui shak', img:'assets/img/product/3_Pui-sha.png'},
            {name:'Lufa', img:'assets/img/product/4_lufa.png'},
            {name:'Noldhugi Shim', img:'/assets/img/product/5_Noldhuhi-Shim.png'},
            {name:'Green Taro Leaf', img:'assets/img/product/6_-Green-Taro-leaf.png'},
            {name:'Purslane', img:'assets/img/product/7_Purslane.png'},
            {name:'Longmelon leaf', img:'assets/img/product/8_-Lau-shak_-Long-melon-leaf.png'},
            {name:'Bitter melon', img:'assets/img/product/8_Bitter-Melon.png'},
            {name:'Winged bean', img:'assets/img/product/9_Winged-Beans.png'},
            {name:'Taro leaf', img:'assets/img/product/10_Taro-leaf.png'},
            {name:'Red spinach', img:'assets/img/product/1_Red-spinach.png'},
            {name:'Green Taro', img:'assets/img/product/2_Green-Taro-Leaf.png'},
            {name:'Malabar spinach', img:'assets/img/product/3_-Malabar-Spinach.png'},
            {name:'Egyptian spinach', img:'assets/img/product/4_Egytian-Spinach.png'},
            {name:'Pumkin leaf', img:'assets/img/product/5_Pumkin-leaf.png'},
            {name:'Bothua', img:'assets/img/product/6--Bothua.png'},
            {name:'Malabar spinach', img:'assets/img/product/9-Malabar-Spinach-Red.png'},
            {name:'Deshi data', img:'assets/img/product/10-_Deshi-Data.png'},
            {name:'Naga chilli', img:'assets/img/product/12_-Naga-Chili.png'},
            {name:'Long egg plant', img:'assets/img/product/13_-Long-Egg-Plant.png'},
            {name:'Kochu loti', img:'assets/img/product/14_-Kochu-Loti.png'},
            {name:'Hairy melon (Matured)', img:'assets/img/product/15-Matured-Hairy-Melon.png'},
            {name:'Flat Bean', img:'assets/img/product/bean.png'},
            {name:'Chichinga', img:'assets/img/product/chichinga.png'},
            {name:'Egg plant', img:'assets/img/product/eggplant.png'},
            {name:'Green pumpkin', img:'assets/img/product/greenpumpkin.png'},
            {name:'Green tomato', img:'assets/img/product/greentomato.png'},
            {name:'Kochur mukhi', img:'assets/img/product/kochurmukhi.png'},
            {name:'Mathi', img:'assets/img/product/mathi.png'},
            {name:'Ridge gourd', img:'assets/img/product/ridgegourd.png'},
            {name:'Hot green chilli', img:'assets/img/product/chilli.png'},
            {name:'Corriander', img:'assets/img/product/corriander.png'},
            {name:'Radish', img:'assets/img/product/radish.png'},
            {name:'Cabbage', img:'assets/img/product/cabbages.png'},
            {name:'Cauli flower', img:'assets/img/product/cauliflowers.png'},
        ];

        this.state = { products };
    }
    render(){
        return(
            <section className="section recent-part">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-heading"><h2>Our products</h2></div>
                        </div>
                    </div>
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                    {this.state.products.map((product, index) => (
                          <ProductCard img={product.img} name={product.name} isnew={true} />
                    ))}
                    </div>
                   
                </div>
            </section>
        )
    }
}