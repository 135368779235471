import { Component } from "react";
import Slider from 'react-slick';

export default class HeroSlider extends Component{
    render(){
        const settings={
            dots:true,
            fade:true,
            infinite:true,
            autoplay:true,
            arrows:true,
            speed:600,
            slidesToShow:1,
            slidesToScroll:1
        }

        return(
            <section className="home-index-slider slider-arrow slider-dots">
                <Slider {...settings}>
                <div className="banner-part banner-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6">
                                <div className="banner-content">
                                    <h1>Get 10% discount on your first order when you signup</h1>
                                    <div className="banner-btn">
                                        <a className="btn btn-inline" href="/signup"><i className="far "></i><span>signup now</span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="banner-img"><img src="assets/img/slider/slider-1.png" alt="index" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-part banner-1">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6">
                                <div className="banner-content">
                                    <h1>Welcome to Ramin's Farm</h1>
                                    <div className="banner-btn">
                                        <a className="btn btn-inline" href="/signup"><i className="far"></i><span>signup now</span></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="banner-img"><img src="assets/img/slider/slider-2.png" alt="index" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                </Slider>
            </section>
        )
    }
}