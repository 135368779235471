import { Component } from "react";

export default class SideBar extends Component{
    render(){
        return(
        <aside className="category-part">
            <div className="category-container">
                <div className="category-header">
                    <a href="/#"><img src="assets/img/logo.png" alt="logo" /></a><button className="category-close"><i className="far fa-times"></i></button>
                </div>
                <ul className="category-list">
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-vegetable"></i>fresh vegetables</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-grocery"></i>groceries & staples</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-harvest"></i>fruits & Juice</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-farm"></i>dairy & eggs</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-crab"></i>sea foods & fishes</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-salad"></i>diet foods</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-dried-fruits"></i>dry foods</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-fast-food"></i>fast foods</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-drinks"></i>drinks</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-coffee-cup"></i>coffee & tea</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                    <li>
                        <a className="cate-link dropdown-link" href="/#"><i className="flaticon-steak"></i>meats</a>
                        <ul className="dropdown-list">
                            <li><a href="/#">carrot</a></li>
                            <li><a href="/#">broccoli</a></li>
                            <li><a href="/#">cauliflower</a></li>
                            <li><a href="/#">cucumber</a></li>
                        </ul>
                    </li>
                </ul>
                <div className="category-footer">
                    <p>All Rights Reserved by <a href="/#">Organic</a></p>
                </div>
            </div>
        </aside>
        )
    }
}