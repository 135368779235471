import React, { Component } from 'react'
import {Alert} from '@material-ui/lab'

export default class Register extends Component{
    constructor(props){
        super(props);
        this.state = {
            Response:null,
            formBody:{
                FirstName:'',
                LastName:'',
                Email:'',
                PostCode:'',
                State:'',
                Phone:'',
                ActivateId:'activateme'
            },
            errormsg:'',
            submitbtntxt: 'create account',
            disable:false
        }

       this.handleChange = this.handleChange.bind(this);
        this.signup = this.signup.bind(this);
        this.verifyForm = this.verifyForm.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.enableButton = this.enableButton.bind(this);
    }

    disableButton = ()=>{
        this.setState({errormsg:''});
        this.setState({Response:null});
        this.setState({disable:true});
    }
    enableButton = ()=>{
        this.setState({disable:false});
    }

    clearForm = ()=>{
        this.setState({
            formBody:{
                ...this.state.formBody,
                FirstName:'',
                LastName:'',
                Email:'',
                PostCode:'',
                State:'',
                Phone:''
            }})
    }
    handleChange = (event , any) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            formBody:{
                ...this.state.formBody,
                [name]:value
            }});
        console.log(this.state.formBody);
      }

   verifyForm = ()=>{
      var isok = true;
      if(this.state.formBody.FirstName.trim() == '' || this.state.formBody.LastName.trim() == '' || this.state.formBody.Email.trim() == '' || this.state.formBody.PostCode.trim() == '' || this.state.formBody.State.trim() == '' || this.state.formBody.Phone.trim() == '')
      {
        isok = false;
      }
      return isok;
   }

   signup = (event)=>{
    
    event.preventDefault();
    this.disableButton();

        if(this.verifyForm()){
            this.setState({errormsg:''});

        fetch(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_SIGNUP_URL, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(this.state.formBody)
                })
                .then((response) =>{
                    this.setState({disable:true});
                   return response.json()}
                )
                 .then((responseData) => {
                    return responseData;
                    }
                    )
                 .then(data=> {
                    this.setState({"Response":data});
                    this.clearForm();
                    this.enableButton();
                    })
               .catch(function(err) {
                  alert('Ops!! Something went wrong. Please try again later.');
                  console.log(err);
                  window.location.reload(false);
               }
            )
            
        }
        else{
                 this.setState({errormsg:'Please fill all the fields. All fields are required.'});
                this.enableButton();
            }
        
    }
    render(){
        
        return(
            <section className="user-form-part">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="user-form-logo">
                                <a href="/#"><img src="assets/img/logo.png" alt="logo" /></a>
                            </div>
                            <div className="user-form-card">
                                <div className="user-form-title">
                                    <h2>Create account!</h2>
                                    <p>Register your account free</p>
                                    
                                </div>
                                <form className="user-form" onSubmit={this.signup} >

                                    <div className="row">
                                        <div className="form-group col-md-6"><label>First Name</label><input type="text" name="FirstName" value={this.state.formBody.FirstName} onChange={this.handleChange} className="form-control" placeholder="Enter your first name" /></div>
                                        <div className="form-group col-md-6"><label>Last Name</label><input type="text" name="LastName"  value={this.state.formBody.LastName}  onChange={this.handleChange} className="form-control" placeholder="Enter your last name" /></div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6"><label>Phone</label><input type="phone" name="Phone"  value={this.state.formBody.Phone}  onChange={this.handleChange} className="form-control" placeholder="Enter your phone number" /></div>
                                        <div className="form-group col-md-6"><label>Email</label><input type="email" name="Email"  value={this.state.formBody.Email}  onChange={this.handleChange} className="form-control" placeholder="Enter your email" /></div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6"><label>State</label>
                                        <select name="State" id="State"  className="form-control" value={this.state.formBody.State}  onChange={this.handleChange}>
                                                <option value="">Select</option>
                                              <option value="NSW">New South Wales</option>
                                              <option value="QLD">Queensland</option>
                                              <option value="VIC">Victoria</option>
                                             <option value="WA">Western Australia</option>
                                             <option value="SA">South Australia</option>
                                             <option value="ACT">Australia Capital Territory</option>
                                             <option value="NT">Northern Territory</option>
                                             <option value="TA">Tasmania</option>
                                        </select>
                                        </div>
                                        <div className="form-group col-md-6"><label>Postcode</label><input type="postcode" name="PostCode"  value={this.state.formBody.PostCode}   onChange={this.handleChange} className="form-control" placeholder="Enter your postcode" /></div>
                                    </div>
                                    <div className="form-button">
                                        <button disabled={this.state.disable} onClick={this.signup}>
                                        {this.state.disable ? 'Processing...' : 'create account'}
                                        </button>
                                        
                                    </div>

                                </form>
                                <br/>
                                {(this.state.Response !== null && this.state.Response.message !== '')? <Alert className="hideen" severity={this.state.Response.severity}>{this.state.Response.message}</Alert>:''}
                                {this.state.errormsg !== '' ? <Alert className="hideen" severity="warning">{this.state.errormsg}</Alert>:''}
                            </div>
                            <div className="user-form-footer">
                                <p>&copy;Copyright 2021 By <a href="/#">RAMINS FARM.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}