import { Component } from "react";

export default class MobileMenu extends Component{
    render(){
        return(
            <div class="mobile-menu">
            <a href="index.html" title="Home Page"><i class="far fa-home"></i><span>Home</span></a>
            <button class="cate-btn" title="Category List"><i class="far fa-th-large"></i><span>category</span></button>
            <button class="cart-btn" title="Cartlist"><i class="far fa-shopping-cart"></i><span>cartlist</span><sup>5</sup></button>
            <a href="wishlist.html" title="Wishlist"><i class="far fa-heart"></i><span>wishlist</span><sup>3</sup></a>
            <a href="compare.html" title="Compare List"><i class="far fa-exchange"></i><span>compare</span><sup>2</sup></a>
        </div>
        )
        }
    }