import { Component } from "react";

export default class MobileNavBar extends Component{
   
    navclosehandler = ()=>{
        this.props.navclose();
    }
    render() {
        return(
            <aside className={this.props.isactive?"mobile-nav active":"mobile-nav"}>
            <div className="nav-container">
                <div className="nav-header">
                    <a href="/#"><img src="assets/img/logo.png" alt="logo" /></a><button onClick={this.navclosehandler} className="nav-close"><i className="fal fa-times"></i></button>
                </div>
                <div className="nav-content">
                    <div className="nav-profile">
                        <a className="nav-user" href="/#"><img src="assets/img/user.jpg" alt="user" /></a>
                        <h4 className="nav-name"><a href="login.html">James Glidewell</a></h4>
                    </div>
                    <ul className="nav-list">
                        <li>
                            <a className="nav-link dropdown-link" href="/#">Home</a>
                            <ul className="dropdown-list">
                                <li><a href="index.html">Home Demo One</a></li>
                                <li><a href="index-2.html">Home Demo Two</a></li>
                                <li><a href="index-3.html">Home Demo Three</a></li>
                                <li><a href="index-4.html">Home Demo Four</a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="nav-link dropdown-link" href="/#">shop</a>
                            <ul className="dropdown-list">
                                <li><a href="shop-grid.html">shop grid</a></li>
                                <li><a href="shop-standard.html">shop standard</a></li>
                                <li><a href="product-details.html">product details</a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="nav-link dropdown-link" href="/#">pages</a>
                            <ul className="dropdown-list">
                                <li><a href="about.html">about us</a></li>
                                <li><a href="contact.html">contact us</a></li>
                                <li><a href="offer.html">offers</a></li>
                                <li><a href="faq.html">faqs</a></li>
                                <li><a href="compare.html">compare</a></li>
                                <li><a href="privacy.html">privacy policy</a></li>
                                <li><a href="coming-soon.html">coming soon</a></li>
                                <li><a href="blank-page.html">blank page</a></li>
                                <li><a href="error.html">404 Error</a></li>
                                <li><a href="wishlist.html">wishlist</a></li>
                                <li><a href="checkout.html">checkout</a></li>
                                <li><a href="cart.html">cart</a></li>
                                <li><a href="orderlist.html">order list</a></li>
                                <li><a href="order-detail.html">order details</a></li>
                                <li><a href="invoice.html">order invoice</a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="nav-link dropdown-link" href="/#">account</a>
                            <ul className="dropdown-list">
                                <li><a href="profile.html">my profile</a></li>
                                <li><a href="login.html">login</a></li>
                                <li><a href="register.html">register</a></li>
                                <li><a href="reset.html">reset password</a></li>
                                <li><a href="change.html">change password</a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="nav-link dropdown-link" href="/#">blogs</a>
                            <ul className="dropdown-list">
                                <li><a href="blog-grid.html">blog grid</a></li>
                                <li><a href="blog-standard.html">blog standard</a></li>
                                <li><a href="blog-details.html">blog details</a></li>
                            </ul>
                        </li>
                        <li>
                            <a className="nav-link" href="contact.html">contact us</a>
                        </li>
                    </ul>
                    <div className="nav-info-group">
                        <div className="nav-info">
                            <i className="icofont-ui-touch-phone"></i>
                            <p><small>call us</small><span>(+1) 123 455 9874</span></p>
                        </div>
                        <div className="nav-info">
                            <i className="icofont-ui-email"></i>
                            <p><small>email us</small><span>info@example.com</span></p>
                        </div>
                    </div>
                    <div className="nav-footer">
                        <p>All Rights Reserved by <a href="/#">Organic</a></p>
                    </div>
                </div>
            </div>
        </aside>
        )
    }
}