import React,{ Component } from 'react';
import {Link,Switch,Route} from 'react-router-dom'
import Home from './components/Home'
import Register from './components/Register'
import { BrowserRouter } from 'react-router-dom';
import ActivateEmail from './components/ActivateEmail';
class App extends Component {
  render(){
    return(
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/signup" component={Register} />
            <Route path="/activate/:id" component={ActivateEmail} />
          </Switch>
        </BrowserRouter>
      </div>
    )
}
}
export default App;