import React, { Component } from 'react'
import {Alert} from '@material-ui/lab'

export default class ActivateEmail extends Component{
    constructor(props){
        super(props);
        this.state = {
            Response: null
        }
    }

    componentDidMount = ()=>{
        const data = {id: this.props.match.params.id}
        fetch(process.env.REACT_APP_BASE_URL+process.env.REACT_APP_MEMBER_ACTIVATE_URL, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)})
          .then(async(response) =>{
              if(response.ok)
              {
              return response.json()
              }
              else{
                  throw new Error(response.status + "Failed fetch");
              }
          })
           .then((responseData) => { // responseData = undefined
               console.log(responseData.message);
               return responseData;
           })
           .then(data=> {this.setState({"Response":data})})
         .catch(function(err) {
            console.log(err);
         })
    };

    render(){
        return(
            <section className="user-form-part">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="user-form-logo">
                                <a href="/#"><img src="/assets/img/logo.png" alt="logo" /></a>
                            </div>
                            <div className="user-form-card">
                                <div className="user-form-title">
                                    <h2>Verify email</h2>
                                    <p>Thanks for joining with Ramin's Farm</p>
                                    
                                </div>
                                
                                <br/>
                                {(this.state.Response !== null && this.state.Response.message !== '')? <Alert className="hideen" severity={this.state.Response.severity}>{this.state.Response.message}</Alert>:''}
                            </div>
                            <div className="user-form-footer">
                                <p>&copy;Copyright 2021 By <a href="/">RAMINS FARM.</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}