import { Component,Fragment } from "react";
import NavBar from '../components/NavBar'
import CatSideBar from '../components/CatSideBar'
import CartSideBar from '../components/CartSideBar'
import MobileNavBar from '../components/MobileNavBar'

export default class Header extends Component{
    state={
        fasearchActive:false,
        myaccountactive:false,
        opencart:false
    }
      
    handleTooglefasearch = () => {
        this.setState({fasearchActive: !this.state.fasearchActive});
    };

    handleToogleaccount = () => {
        this.setState({myaccountactive: !this.state.myaccountactive});
    };

    handleToogleCart =() =>{
        this.setState({opencart: !this.state.opencart})
    }
    render(){
        const IsFaSearchActive = this.state.fasearchActive;
        return(
            <Fragment>
            <div className="header-part">
                <div className="container">
                    <div className="header-content-group">
                        <div className="header-widget-group left" >
                            <div className="header-widget header-user"  title="My Account" onClick={this.handleToogleaccount}></div><a className="header-logo" href="/"><img src="assets/img/logo.png" alt="logo" /></a>
                            <button className="header-widget header-src" title="Search Bar"></button>
                            <button className="header-widget header-cate" title="Category List"></button>
                        </div>
                        <div className={IsFaSearchActive ? "header-form active":"header-form-less"}>
                        </div>
                        <div className="header-widget-group right">
                        <div className="navbar-info">
                                    <i className="fal fa-phone"></i>
                                    <p><small>call us</small><span>{process.env.REACT_APP_RF_PHONE_NUMBER}</span></p>
                                </div>
                                <div className="navbar-info">
                                    <i className="fal fa-envelope-open-text"></i>
                                    <p><small>email us</small><span>{process.env.REACT_APP_RF_EMAIL}</span></p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <CatSideBar />
            <CartSideBar isactive={this.state.opencart} cartclose={this.handleToogleCart} />
            <MobileNavBar isactive={this.state.myaccountactive} navclose={this.handleToogleaccount} />
           </Fragment>
        )
    };
}